<template>
  <validation-provider
    :mode="vmode"
    :vid="vid"
    :name="name"
    :rules="rules"
    v-slot="{ dirty, validated, valid, errors }"
  >
    <b-form-group
      :id="`input-group-${uid}`"
      :label="`${label}`"
      :label-for="`input-${uid}`"
      :class="{
        asterisk:
          Object.keys(rules).includes('required') ||
          (Object.keys(rules).includes('required_if') && !valid) ||
          (Object.keys(rules).includes('required_with') && !valid),
      }"
    >
      <monthpicker
        :class="getValidationStateClass({ dirty, validated, valid, errors })"
        :id="id"
        :name="`input-${uid}`"
        :monthLabels="customMonths"
        :dateFormat="monthOnly ? 'MM' : 'MM/YYYY'"
        selectedBackgroundColor="#f9991e"
        :clearOption="clearable"
        v-model="inputVal"
        @selected="onSelected"
        :aria-describedby="`input-${id}-live-feedback`"
      />
      <span class="prettified-month" v-if="monthOnly">{{
        prettifyInputVal
      }}</span>
      <b-form-invalid-feedback :id="`input-${id}-live-feedback`">{{
        errors[0]
      }}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
import moment from "moment";
moment.locale("it");
export default {
  props: {
    value: undefined,
    id: undefined,
    vid: String,
    name: String,
    label: String,
    clearable: Boolean,
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
    vmode: {
      type: String,
      default: "aggressive",
    },
    monthOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      uid: null,
      customMonths: [
        "Gen",
        "Feb",
        "Mar",
        "Apr",
        "Mag",
        "Giu",
        "Lug",
        "Ago",
        "Set",
        "Ott",
        "Nov",
        "Dic",
      ],
      fullMonths: [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre",
      ],
    };
  },
  mounted() {
    this.uid = this._uid;
  },
  computed: {
    inputVal: {
      get() {
        // console.debug(`inputVal GET: ${JSON.stringify(this.value)}`);
        return this.value;
      },
      set(val) {
        // console.debug(`inputVal SET: ${JSON.stringify(val)}`);
        this.$emit("input", val);
      },
    },
    prettifyInputVal() {
      const index = moment(this.inputVal).format("M") - 1;
      return this.fullMonths[index];
    },
  },
  methods: {
    onSelected(value) {
      // console.debug("month-picker onSelected", value);
      if (!value) {
        // has been cleared!
        this.$emit("clear");
      }
    },
    getValidationStateClass({ dirty, validated, valid = null, errors = null }) {
      if (errors[0]) return "is-invalid";
      // return dirty || validated ? valid : null;
      return !Object.keys(this.rules).length
        ? ""
        : dirty || validated
        ? valid
          ? "is-valid"
          : "is-invalid"
        : "";
    },
  },
};
</script>
<style lang="css" scoped>
:deep(:has(.prettified-month) .flexbox:not(.monthItem)) {
  display: none !important;
  pointer-events: none;
  touch-action: none;
  cursor: default;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
:deep(:has(.prettified-month)) {
  position: relative;
}
:deep(.prettified-month) {
  display: inline-flex;
  font-size: 12px;
  position: absolute;
  z-index: 1;
  background: #fff;
  top: 9px;
  left: 5px;
  height: 20px;
  line-height: 1em;
  padding: 2px 10px 2px 5px;
  pointer-events: none;
  touch-action: none;
  cursor: default;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
</style>
